
.customBootstrapTable {
  .card-header {
    border-bottom: 1px solid darkgrey;
  }
  th {
    //background-color: $tableHeaderBgColor;
    color: white;
    font-weight: normal;
    padding: 4px;
    position: relative;
    height: 30px;
    border: 1px solid #dedede;
    vertical-align: initial !important;
    text-align: center !important;
    span {
      color: #d3d2d2 !important;
    }
  }
  td {
    border: 1px solid #dedede;
    padding: 9px 10px;
    //-webkit-user-select: all; /* Safari */
    //-ms-user-select: all; /* IE 10 and IE 11 */
    //user-select: all;
  }
  tr:nth-of-type(odd) {
    background-color: #f9f9f9 !important;
  }
  .trhead {
    position: fixed;
  }
  .scroll {
    max-height: 100vh;
    display: flex;
    position: relative;
    .load {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .fixed-footer {
    margin-top: 20px;
  }
  .loading {
    text-align: center;
    padding-top: 20px;
    img {
      height: 80px;
    }
  }
}

@media screen and (max-width: 992px) {
  .grid-filter.card {
    #filterTogle {
      .row {
        display: block;
      }
      .DateRangePickerInput {
        .DateRangePickerInput_arrow,
        .DateInput,
        .DateRangePickerInput_clearDates {
          display: block;
        }
        .DateRangePickerInput_arrow {
          padding: 0 20px;
        }
      }
    }
  }
  .customBootstrapTable {
    .scroll {
      overflow: -webkit-paged-x;
    }
  }
}
